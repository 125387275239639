import React, { useState, useEffect, Fragment } from "react";
import styles from "../home.module.css";
import hero_bg from "../../resources/imgs/hero_bg.png";
import globe from "../../resources/imgs/globe.png";
import logo from "../../resources/imgs/logo.png";
import { useJwt } from "react-jwt";
import { useMedia } from "react-use";
import Modal from "react-modal";

function Hero() {
  const [visible, setVisible] = useState(false);
  const ismob = useMedia("(max-width: 756px)");
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [modalIsOpen, setIsOpen] = useState(false);
  const { decode, expired } = useJwt(
    localStorage.getItem("creds") === null
      ? ""
      : localStorage.getItem("creds").toString()
  );
  const [remember_me, setRememberMe] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (!expired && decode) {
      setCredentials({ email: decode.email, password: decode.password });
    }
    //eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <div
        className={styles.hero_main_container}
        style={{ backgroundImage: `url(${hero_bg})` }}
      >
        <div className={styles.navbar_main_container}>
          <div className={styles.language_box}>
            <img src={globe} alt="language" />
            <select style={ismob ? { fontSize: "0px" } : {}}>
              <option value="English"> English </option>
            </select>
          </div>
          {ismob && (
            <button className={styles.login_btn_mob} onClick={openModal}>
              {" "}
              Sign In{" "}
            </button>
          )}
        </div>
        <div className={styles.hero_main_content}>
          <div className={styles.hero_left}>
            <img src={logo} alt="site_logo" />
            <h1>
              {" "}
              WATCH GREAT FILMS <b> FOR FREE ! </b>
            </h1>
            <div className={styles.subscription_box}>
              <div>
                <h1 style={{ color: "black", fontWeight: "400" }}>
                  Register you account for
                </h1>
                <h1 style={{ color: "#BE393F" }}>
                  <b>Free subscription now!</b>
                </h1>
                <p
                  align="center"
                  style={{ marginTop: "1rem", fontWeight: "400" }}
                >
                  And get access to tens of thousands of content, unlimited
                  downloads and streaming. <br />{" "}
                  <b> Create your account in less than a minute</b>
                </p>
              </div>
              <div className={styles.subs_features}>
                <h3> 1. Registration </h3>
                <h3> 2. Validate </h3>
                <h3> 3. Unlimited Access </h3>
              </div>
            </div>
          </div>
          {ismob ? null : (
            <div className={styles.hero_right}>
              <h2> Sign In </h2>
              <span className={styles.input}>
                <input
                  type="text"
                  placeholder="Enter Your Email"
                  value={credentials.email}
                  onChange={(e) => {
                    setCredentials({ ...credentials, email: e.target.value });
                  }}
                />
              </span>
              <span className={styles.input}>
                <input
                  type={visible ? "text" : "password"}
                  value={credentials.password}
                  onChange={(e) => {
                    setCredentials({
                      ...credentials,
                      password: e.target.value,
                    });
                  }}
                  placeholder="Enter Password"
                />
                {visible ? (
                  <i
                    class="fa-solid fa-eye"
                    onClick={(e) => {
                      e.preventDefault();
                      setVisible(!visible);
                    }}
                  ></i>
                ) : (
                  <i
                    class="fa-solid fa-eye-slash"
                    onClick={(e) => {
                      e.preventDefault();
                      setVisible(!visible);
                    }}
                  ></i>
                )}
              </span>
              <span className={styles.remember_box}>
                <input
                  type="checkbox"
                  value={remember_me}
                  onClick={(e) => {
                    setRememberMe(!remember_me);
                  }}
                  checked={remember_me}
                />
                <label> Remember me </label>
              </span>
              <button className={styles.login_btn}>Sign In</button>
              <span className={styles.tag}>
                <h3 style={{ color: "#858181" }}>New to Greatfilms.TV?</h3>
                <h3 style={{ color: "#FCFCFC" }}>Register Now.</h3>
              </span>
            </div>
          )}
        </div>
      </div>
      {ismob && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            content: {
              left: "50%",
              right: "auto",
              bottom: "auto",
              transform: "translate(-50%, 10%)",
              width: "90%",
              background: "#D9C077",
              zIndex: "300",
              padding: "0px",
              background: "transparent",
              border: "none",
            },
          }}
          id="modal"
          contentLabel="Login Modal"
        >
          <div
            className={styles.hero_right}
            style={{ width: "100%", display: "flex", marginTop: "0px" }}
          >
            <h2> Sign In </h2>
            <span className={styles.input} style={{ padding: "1rem 2rem" }}>
              <input
                type="text"
                placeholder="Enter Your Email"
                value={credentials.email}
                onChange={(e) => {
                  setCredentials({ ...credentials, email: e.target.value });
                }}
              />
            </span>
            <span className={styles.input} style={{ padding: "1rem 2rem" }}>
              <input
                type={visible ? "text" : "password"}
                value={credentials.password}
                onChange={(e) => {
                  setCredentials({ ...credentials, password: e.target.value });
                }}
                placeholder="Enter Password"
              />
              {visible ? (
                <i
                  class="fa-solid fa-eye"
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(!visible);
                  }}
                ></i>
              ) : (
                <i
                  class="fa-solid fa-eye-slash"
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(!visible);
                  }}
                ></i>
              )}
            </span>
            <span className={styles.remember_box}>
              <input
                type="checkbox"
                value={remember_me}
                onClick={(e) => {
                  setRememberMe(!remember_me);
                }}
                checked={remember_me}
              />
              <label> Remember me </label>
            </span>
            <button className={styles.login_btn}>Sign In</button>
          </div>
        </Modal>
      )}
    </Fragment>
  );
}

export default Hero;
