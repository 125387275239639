import React from "react";
import { Link } from "react-router-dom";
import api from "../../Environment";
import Helper from "../Helper/helper";
import configuration from "react-global-configuration";
import {t, translate} from "react-multi-lang";
import { gapi } from "gapi-script";

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class AutoLoginComponent extends Helper {
  state = {
    data: {
      email: "",
      password: "",
      timezone: const_time_zone, 
    },
    loadingContent: null,
    buttonDisable: false,
  };

  componentDidMount() {
    this.state.data.email = this.props.match.params.email;
    this.state.data.password = this.props.match.params.password;
    api
        .postMethod("v4/login", this.state.data)
        .then((response) => {
          if (response.data.success) {
            localStorage.setItem("userId", response.data.data.user_id);
            localStorage.setItem("accessToken", response.data.data.token);
            localStorage.setItem("userType", response.data.data.user_type);
            localStorage.setItem("push_status", response.data.data.push_status);
            localStorage.setItem("username", response.data.data.name);
            localStorage.setItem(
                "active_profile_id",
                response.data.data.sub_profile_id
            );
            this.props.history.push("/view-profiles");
            this.setState({
              loadingContent: null,
              buttonDisable: false,
            });
          } else {
            this.props.history.push("/login");
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loadingContent: null, buttonDisable: false });
        });
  }

  render() {
    const { t } = this.props;
    const { data } = this.state;

    return (
      <div>
        autologin
      </div>
    );
  }
}

export default AutoLoginComponent;
