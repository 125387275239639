import React from "react";
import styles from "../home.module.css";

function FeatureCard({ item, index }) {
  return (
    <div
      className={styles.feature_card_main}
      style={{ flexDirection: index % 2 === 0 ? "row" : "row-reverse" }}
    >
      <div>
        <h1 className={styles.item_heading}> {item.heading} </h1>
        <h4 className={styles.item_description}> {item.description} </h4>
      </div>
      <img src={item.img} alt="feature_description" />
    </div>
  );
}

export default FeatureCard;
