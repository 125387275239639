import React, { useState } from "react";
import FeatureCard from "./components/FeatureCard";
import Hero from "./components/Hero";
import styles from "./home.module.css";
import f1 from "../resources/imgs/f1.png";
import f2 from "../resources/imgs/f2.png";
import f3 from "../resources/imgs/f3.png";
import LandingFooter from "./components/LandingFooter";

function HomeLanding() {
  //eslint-disable-next-line
  const [feature_list, setFeatureList] = useState([
    {
      img: f1,
      description:
        "Watch on smart TVs, PlayStation, Xbox, Chromecast, Apple TV, Blu-ray players and more.",
      heading: "Compatible with All devices",
    },
    {
      img: f2,
      description:
        "Watch on smart TVs, PlayStation, Xbox, Chromecast, Apple TV, Blu-ray players and more.",
      heading: "Download your Show to watch free & unlimited Offline",
    },
    {
      img: f3,
      description: "Easy sign-up",
      heading: "Compatible with All devices",
    },
  ]);
  return (
    <div className={styles.main_page}>
      <Hero />
      {feature_list.map((item, index) => {
        return <FeatureCard item={item} index={index} />;
      })}
      <LandingFooter />
    </div>
  );
}

export default HomeLanding;
