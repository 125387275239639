import React from "react";
import styles from "../home.module.css";
import fo1 from "../../resources/imgs/fo1.png";
import fo2 from "../../resources/imgs/fo2.png";
import fo3 from "../../resources/imgs/fo3.png";
import fo4 from "../../resources/imgs/fo4.png";
import fo5 from "../../resources/imgs/fo5.png";
import fo6 from "../../resources/imgs/fo6.png";
import {useMedia} from "react-use";

function LandingFooter() {
  const ismob = useMedia("(max-width: 756px)");
  const footer_feature = [
    {
      img: fo1,
      description: "Search for anything you want",
    },
    {
      img: fo2,
      description: "Intuitive Interface. Easy to use interface",
    },
    {
      img: fo3,
      description: "New content added regularly",
    },
    {
      img: fo4,
      description: "Enjoy Ad free nonstop content.",
    },
    {
      img: fo5,
      description: "Sign-up in two clicks and access our catalogue immediately",
    },
    {
      img: fo6,
      description: "Enjoy watching great films Anywhere, Anytime",
    },
  ];
  return (
    <div className={styles.landing_footer_main}>
      {ismob ? (
        <h1 className={styles.footer_heading}>
          Compatible with All Find out why thousands of people sign up daily.
        </h1>
      ) : (
        <h1 className={styles.footer_heading}>
          Compatible with All Find out why thousands <br /> of people sign up
          daily.
        </h1>
      )}
      <h4 className={styles.footer_subheading}>
        It's HERE and it's FREE. Here's why you should join :
      </h4>
      <div className={styles.footer_feature}>
        {footer_feature.map((item) => {
          return (
            <div className={styles.footer_item}>
              <img src={item.img} alt="footer_feature" />
              <h4> {item.description} </h4>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LandingFooter;
